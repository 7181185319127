<template>
    <b-container fluid>
      <b-row>
        <b-col lg="12" sm="12">
          <body-card>
            <template v-slot:body>
                  <b-overlay :show="loader">
                      <b-row>
                          <b-col xl="12" lg="12" sm="12">
                              <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                                  <b-form @submit.prevent="handleSubmit(markAssign)" @reset.prevent="reset">
                                      <b-row>
                                           <!-- mark -->
                                           <!-- only for category 8 -->
                                          <b-col lg="12" sm="12" v-if="parseInt(appDetail?.category?.category_code) === 8">
                                            <ValidationProvider name="Mark Assign" vid="final_mark" :rules="{required:true, min_value: 0, max_value: 100}">
                                                  <b-form-group
                                                      class="row"
                                                      label-cols-sm="3"
                                                      label-for="final_mark"
                                                      slot-scope="{ valid, errors }">
                                                      <template v-slot:label>
                                                      {{ $t('national_award.number') }}
                                                      </template>
                                                      <b-form-input
                                                      oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                      id="final_mark"
                                                      v-model.number="data.final_mark"
                                                      :state="errors[0] ? false : (valid ? true : null)"
                                                      ></b-form-input>
                                                      <div class="invalid-feedback d-block">
                                                      {{ errors[0] }}
                                                      </div>
                                                  </b-form-group>
                                              </ValidationProvider>
                                          </b-col>
                                          <!-- for category 7 -->
                                          <b-row>
                                            <b-col sm="12">
                                            <table class="table table-sm table-bordered table-striped">
                                                <thead>
                                                    <tr class="font-weight-bold">
                                                        <td class="text-center">{{ $t('national_award.title') }}</td>
                                                        <td class="text-center">{{ $t('national_award.standard_value') }}</td>
                                                        <td class="text-center">{{ $t('national_award.application_info') }}</td>
                                                        <td class="text-center">{{ $t('national_award.number') }}</td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(item, index) in appDetail?.category?.details" :key="index">
                                                        <td class="text-center">
                                                            <span v-if="index === 0">{{ $t('national_award.packet_picture_mark') }}</span>
                                                            <span v-if="index === 1">{{ $t('national_award.ingredients_use_in_packet_mark') }}</span>
                                                            <span v-if="index === 2">{{ $t('national_award.packet_details_design_mark') }}</span>
                                                            <span v-if="index === 3">{{ $t('national_award.packet_uses_facilities_mark') }}</span>
                                                        </td>
                                                        <td class="text-center" style="width:15%">{{$n(item.standard_value)}}</td>
                                                        <td class="text-center" style="width:30%">
                                                            <span v-if="index === 0">
                                                                <a target="_blank"
                                                                :href="teaGardenServiceBaseUrl + (isImage(appDetail.detail.packet_picture_attachment) ? 'storage/' : '') + appDetail.detail.packet_picture_attachment">
                                                                <i style="font-weight:bold" class="ri-download-line download-btn"></i>
                                                                </a>
                                                            </span>
                                                            <span v-if="index === 1">
                                                                {{ appDetail.detail.ingredients_use_in_packet }}
                                                            </span>
                                                            <span v-if="index === 2">
                                                                <a target="_blank"
                                                                :href="teaGardenServiceBaseUrl + (isImage(appDetail.detail.packet_details_design_attachment) ? 'storage/' : '') + appDetail.detail.packet_details_design_attachment">
                                                                <i style="font-weight:bold" class="ri-download-line download-btn"></i>
                                                                </a>
                                                            </span>
                                                            <span v-if="index === 3">
                                                                {{ appDetail.detail.packet_uses_facilities }}
                                                            </span>
                                                        </td>
                                                        <td style="width:15%" class="text-center">
                                                            <ValidationProvider name="Number" :vid="'number-' + index" :rules="'required|min_value: 0|max_value:' + item.standard_value">
                                                                <b-form-group
                                                                slot-scope="{ valid, errors }"
                                                                label-for="number">
                                                                <b-form-input
                                                                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                                    id="number"
                                                                    v-model="item.number"
                                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                                ></b-form-input>
                                                                <div class="invalid-feedback">
                                                                    {{ errors[0] }}
                                                                </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            </b-col>
                                        </b-row>
                                          <!-- remarks -->
                                          <b-col lg="12" sm="12" v-if="parseInt(appDetail?.category?.category_code) === 8">
                                            <ValidationProvider name="Remarks" vid="mark_remarks" :rules="{required:false}">
                                                  <b-form-group
                                                      class="row"
                                                      label-cols-sm="3"
                                                      label-for="mark_remarks"
                                                      slot-scope="{ valid, errors }">
                                                      <template v-slot:label>
                                                      {{ $t('globalTrans.remarks') }}
                                                      </template>
                                                      <b-textarea
                                                      id="mark_remarks"
                                                      v-model.number="data.mark_remarks"
                                                      :state="errors[0] ? false : (valid ? true : null)"
                                                      ></b-textarea>
                                                      <div class="invalid-feedback d-block">
                                                      {{ errors[0] }}
                                                      </div>
                                                  </b-form-group>
                                              </ValidationProvider>
                                          </b-col>
                                      </b-row>
                                      <div class="row">
                                          <div class="col-sm-3"></div>
                                          <div class="col text-right">
                                              <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                              &nbsp;
                                              <b-button variant="danger" class="mr-1" @click="$bvModal.hide('mark-assign')">{{ $t('globalTrans.cancel') }}</b-button>
                                          </div>
                                      </div>
                                  </b-form>
                              </ValidationObserver>
                          </b-col>
                      </b-row>
                  </b-overlay>
            </template>
          </body-card>
        </b-col>
      </b-row>
    </b-container>
  </template>
  <script>
  import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
  import { btbTeaAwardApplicationMarkAssignApi } from '../../../api/routes'
  export default {
      name: 'FormLayout',
      props: ['id'],
      created () {
        if (this.id) {
          const tmp = this.getInfo()
          this.data.company_name_en = tmp.company_name_en
          this.data.company_name_bn = tmp.company_name_bn
          this.data.status = 2
        }
      },
      mounted () {
      },
      data () {
          return {
              saveBtnName: this.id ? this.$t('globalTrans.approve') : this.$t('globalTrans.save'),
              teaGardenServiceBaseUrl: teaGardenServiceBaseUrl,
              data: {
                  final_mark: '',
                  packet_picture_mark: 0,
                  packet_details_design_mark: 0,
                  packet_uses_facilities_mark: 0,
                  ingredients_use_in_packet_mark: 0,
                  mark_remarks: ''
              },
              appDetail: {},
              loader: false
          }
      },
      computed: {
        finalMark () {
            let total = 0
            this.appDetail.category.details.forEach(item => {
                if (item.number) {
                    total += parseFloat(item.number)
                }
            })
            return total
        }
      },
      methods: {
          getInfo () {
              const tmpData = this.$store.state.list.find(item => item.id === this.id)
              this.appDetail = tmpData
              return JSON.parse(JSON.stringify(tmpData))
          },
          async markAssign () {
              this.loader = true

             // when category id 7
              if (parseInt(this.appDetail?.category?.category_code) === 7) {
                this.data.final_mark = this.finalMark
                this.data.packet_picture_mark = this.appDetail?.category?.details[0]?.number
                this.data.ingredients_use_in_packet_mark = this.appDetail?.category?.details[1]?.number
                this.data.packet_details_design_mark = this.appDetail?.category?.details[2]?.number
                this.data.packet_uses_facilities_mark = this.appDetail?.category?.details[3]?.number
              }
              this.$store.dispatch('mutateCommonProperties', { loading: true })
              let result = null
              const loadinState = { loading: false, listReload: true }
              result = await RestApi.postData(teaGardenServiceBaseUrl, `${btbTeaAwardApplicationMarkAssignApi}/${this.id}`, this.data)
              this.$store.dispatch('mutateCommonProperties', loadinState)
              if (result.success) {
                  this.$toast.success({
                      title: this.$t('globalTrans.success'),
                      message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                      color: '#D6E09B'
                  })
                  this.$bvModal.hide('mark-assign')
              } else {
                  this.$refs.form.setErrors(result.errors)
              }
              this.loader = false
          },
          isImage (path) {
            return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(path)
          }
      }

  }
  </script>
